<section class="py-4" [ngClass]="{'mlsj-bg': competition?.id_modalidad == 71}">
  <div class="container" *ngIf="competition">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <!-- <img class="card-img-top" [src]="show.banner" alt="Show Banner"> -->
          <div class="card-body">
            <div class="row">
              <div class="col-xl-7">
                <h1 class="card-title">{{competition.numero}} - {{competition.nombre}}</h1>
                <h2>{{competition.clave_modalidad}} - {{competition.nombre_modalidad}}</h2>
                <h3>{{competition.calificados}} / {{competition.participantes}}</h3>
                <h4 *ngIf="competition.hard_start == 1; else rolling">{{competition.inicio * 1000| date:'dd/MMM h:mm a'}}</h4>
                <ng-template #rolling>
                  <h4>Rolling start after previous class</h4>
                </ng-template>
                <h5 *ngIf=" competition.tiempo_acordado">Time Allowed: {{competition.tiempo_acordado | number}}</h5>
                <h5 *ngIf="competition.tiempo_acordado_2 != '0.00' && competition.tiempo_acordado_2">Time Allowed 2:
                  {{competition.tiempo_acordado_2}}</h5>
                <div *ngIf="competition.message">Mensaje:
                  <i class="mdi mdi-message text-success"></i>
                  <span style="font-style: italic;">
                    {{competition.message}}
                  </span>
                </div>


                <div><a *ngIf="competition.croquis" href="{{competition.croquis}}" target="_blank">Course Design</a>
                </div>
              </div>
              <div class="col-xl-5 text-right">
                <div *ngIf="isValidYoutubeUrl(competition.youtube_url); else otherStreaming">
                  <iframe [src]="youtubeEmbed | safe:'url'" frameborder='0' allowfullscreen>
                  </iframe>
                </div>
                <ng-template #otherStreaming>
                  <a *ngIf="competition.youtube_url" href="{{competition.youtube_url}}" target="_blank"
                    style="text-align: right;">
                    <i class="mdi mdi-youtube text-danger" style="font-size: 5em; margin-right: 5px;"></i>
                    <div
                      style="text-align: right; margin-right: 15px; color: black; font-weight: bold; margin-top: -10px;">
                      Video</div>
                  </a>
                </ng-template>
                <div class="btn btn-primary" (click)="makecsv()">Download CSV</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- IN RING ROW-->
    <div class="row" *ngIf="competition.estatus_prueba == '1'">
      <div class="col-12">
        <div class="card">
          <div class="card-title"></div>
          <div class="card-body">
            <h3 class="header-title mb-3">In Ring</h3>
            <ng-container *ngFor="let result of results">
              <ng-container *ngIf="result.en_pista == '1'">
                <div class="table-responsive" class="inRingData">
                  <table class="table ">
                    <tr>
                      <td>{{result.rankTabla}}</td>
                      <td style="text-align: center;"><img [src]="result.riderBadge" alt="" style="max-height: 50px; max-width: 50px;"><br>
                        <span class="small">{{result.riderAcronym}}</span>
                      </td>
                      <td>{{result.jinete||result.riderName}}</td>
                      <td>{{result.caballo||result.horseName}}</td>
                      <td>{{result.faltasTabla == '' ? '0' : result.faults | number}} | {{competition.liveTime}}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- RESULTS PANEL -->
    <div class="row" *ngIf="!competition.ocultar_entrada; else ordenOculto">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3 class="header-title mb-3">Results</h3>
            <div class="mb-3" *ngIf="categorias.length > 1">
              <button type="button" class="btn btn-light mr-3" *ngFor="let categoria of categorias"
                [ngClass]="{'active': categoriaSeleccionada == categoria.value }"
                (click)="categoriaSeleccionada = categoria.value;">
                {{ categoria.nombre }}
              </button>
            </div>
            <div class="table-responsive" *ngIf="competition.id_modalidad != '71'; else teamCompetition">
              <table class="table table-striped table-sm">
                <tbody>
                  <tr *ngFor="let result of filteredResults()" [ngClass]="result.type == 'break' ?  'breakRow' : '' ">
                    <td>{{(result.rank == '0' ? '' : result.rank )}} <br> <span *ngIf="result.startTime"> {{result.startTime}}</span></td>
                    <td style="text-align: center;">
                      <img *ngIf="result.type != 'break'" [src]="result.riderBadge" alt=""
                        style="max-height: 50px; max-width: 50px;"><br>
                      <span *ngIf="result.type != 'break'" class="small">{{result.riderAcronym}}</span>
                    </td>
                    <td>
                      <a *ngIf="result.type != 'break' " [href]="isUS ? '/profile/'+result.riderId+'/'+showId :  '/rider-profile/'+result.riderId " style="color: black;" title="Rider Profile">{{result.jinete||result.riderName}}</a>

                      <span *ngIf="result.type == 'break' ">{{result.jinete||result.riderName}}</span>

                    </td>
                    <td>
                      <a *ngIf="result.type != 'break'" [href]="'/horse-profile/'+result.horseId" style="color: black;"><strong>{{result.entryNumber}}
                        </strong>{{result.caballo||result.horseName}}
                      </a>
                      <span *ngIf="result.type == 'break'">{{result.caballo||result.horseName}}</span>
                    </td>
                    <td style="min-width: 100px;">{{result.result1}} <br> {{result.result2}} <br>
                      <span class="small italic">
                        {{result.prize}}
                      </span>
                    </td>
                    <td>
                      <a href="/clip-viewer/?clip={{ encodeClip(result?.video1Url) }}"
                        *ngIf="result.video1Url && result.video1 " target="_blank">
                        <i class="mdi mdi-play-circle text-success" style="font-size: 2em;"></i>
                      </a>
                      <a href="/clip-viewer/?clip={{encodeClip(result.video2Url)}}"
                        *ngIf="result.video2Url && result.video2 " target="_blank">
                        <i class="mdi mdi-play-circle text-success" style="font-size: 2em;"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ng-template #teamCompetition>
              <div *ngIf="teams.length > 0">
                <div class="card" *ngFor="let team of teams">
                  <div class="card-header bg-dark text-white">
                    <div class="align-items">
                      <p class="mlsj-p">#{{(team.rank)}} .- </p>
                      <img [src]="team.teamBadge" alt="{{ team.teamName }} Logo" class="img-50">
                      <p class="mlsj-p">{{ team.teamName | uppercase }}</p>
                    </div>
                    <div class="results">
                      <div class="mlsj-teamScore1" *ngIf="team.result1">{{ team.result1 }}</div>
                      <div class="mlsj-teamScore2" *ngIf="team.result2">{{ team.result2 }}</div>
                      <div
                        [ngClass]="{'mlsj-teamScore3': (team.rank == 3 || team.rank == 4), 'mlsj-teamScore4': (team.rank == 1 || team.rank == 2)}"
                        *ngIf="team.result3">{{ team.result3 }}</div>
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table table-sm">
                      <thead>
                        <th style="width: 40%;">Rider</th>
                        <th style="width: 40%;">Horse</th>
                        <th style="width: 20%;">Results</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let rider of team.riders">
                          <td>{{ rider.riderName }}</td>
                          <td>{{ rider.horseName }}</td>
                          <td>
                            <div>
                              <div class="mlsj-teamScore1">
                                <a href="/clip-viewer/?clip={{ encodeClip(rider.video1) }}" target="_blank"
                                  *ngIf="rider.video1 && rider.video1 != ''">
                                  <i class="mdi mdi-play-circle text-white mdi-18px"></i>
                                </a>
                                <a href="/clip-viewer/?clip={{ encodeClip(rider.video2) }}" target="_blank"
                                  *ngIf="rider.video2 && rider.video2 != ''">
                                  <i class="mdi mdi-play-circle text-white mdi-18px"></i>
                                </a>
                                {{ rider.result1 }}
                              </div>
                              <div class="mlsj-teamScore2" *ngIf="rider.result2">{{ rider.result2 }}</div>
                              <div
                                [ngClass]="{'mlsj-teamScore3': (team.rank == 3 || team.rank == 4), 'mlsj-teamScore4': (team.rank == 1 || team.rank == 2)}"
                                *ngIf="rider.result3">{{ rider.result3 }}</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> <!-- end card-body-->
                </div>
              </div>

            </ng-template>
          </div>
        </div>
      </div>

    </div>


  </div>
</section>

<ng-template #ordenOculto>
  <div class="card">
    <div class="card-body">
      Orden de sin publicar.
    </div>
  </div>
</ng-template>
