import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collectionGroup, query, where, } from "firebase/firestore";  
import { Rider } from '../models/rider.interface';
import { Horse } from '../models/horse.interface';
import { Result } from '../models/result.interface';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-rider-profile',
  templateUrl: './rider-profile.component.html',
  styleUrls: ['./rider-profile.component.scss']
})
export class RiderProfileComponent implements OnInit {
  public riderId: string = '';
  public rider: Rider | null = null;
  public podiums: number = 0;
  public classesEntered: number = 0;
  public wins: number = 0;
  public results: Result[] = [];
  public horses: Horse[] = [];
  public prefix: string = '';
  public isLoading: boolean = true;
  public currentPage: number = 1;
  public horsesPerPage: number = 8;
  public totalPages: number = 1;
  public paginatedHorses: Horse[] = [];
  public showAllHorses: boolean = false;
  public horseCategories = [
    { id: 'all', name: 'All Horses' },
    { id: 'recent', name: 'Recently Ridden' }
  ];
  public currentCategory: string = 'recent';
  public filteredHorses: Horse[] = [];
  public selectedHorseId: string | null = null;
  public filteredResults: Result[] = [];
  public sortColumn: string = 'date';
  public sortDirection: 'asc' | 'desc' = 'desc';
  //public resultsFb: any;
  //public horsesFb: any;
  // public pruebas: any;
  // public ipcs: any;


  constructor(private route: ActivatedRoute, private titleService: Title, private metaService: Meta, private db: AngularFirestore, @Inject(DOCUMENT) private document: Document) {
    this.riderId = this.route.snapshot.paramMap.get('riderId');
    
    // Set default title and meta tags (will be updated when rider data loads)
    this.titleService.setTitle('Rider Profile | Ecuestre Digital');
    
    // Basic SEO meta tags
    this.metaService.updateTag({ name: 'description', content: 'View detailed equestrian rider profile with statistics, horses, and competition results.' });
    this.metaService.updateTag({ name: 'keywords', content: 'equestrian, rider profile, horse riding, show jumping, competition results' });
    
    // Open Graph tags for Facebook and other social media
    this.metaService.updateTag({ property: 'og:type', content: 'profile' });
    this.metaService.updateTag({ property: 'og:title', content: 'Rider Profile | Ecuestre Digital' });
    this.metaService.updateTag({ property: 'og:description', content: 'View detailed equestrian rider profile with statistics, horses, and competition results.' });
    this.metaService.updateTag({ property: 'og:site_name', content: 'Ecuestre Digital' });
    
    // Twitter Card tags
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metaService.updateTag({ name: 'twitter:title', content: 'Rider Profile | Ecuestre Digital' });
    this.metaService.updateTag({ name: 'twitter:description', content: 'View detailed equestrian rider profile with statistics, horses, and competition results.' });
    this.metaService.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
  }



  async ngOnInit() {
    this.isLoading = true;
    this.currentCategory = 'recent';
    
    try {
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "7cc263a1-a490-4337-8302-151490495e56");
      myHeaders.append("Content-Type", "application/json");
      let url = 'https://api.ecuestre.digital/profiles/rider/';



      if (this.riderId.includes('us_')) {
        console.log('US Rider');
        url = 'https://api.equestrian.digital/profiles/rider/';
        this.riderId = this.riderId.replace('us_', '');
        this.prefix = 'us_';
      }

      var raw = JSON.stringify({
        "riderId": this.riderId
      });

      console.log(this.riderId);

      var requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(url, requestOptions)
        //fetch("https://j7iuf3kjzc.execute-api.us-west-1.amazonaws.com/Production", requestOptions)
        .then(response => response.json())
        .then(result => {
          //result = JSON.parse(result);
          this.rider = result['riderInfo'];
          
          // Update title and meta tags with rider-specific information
          const pageTitle = `${this.rider.riderName} - Rider Profile | Ecuestre Digital`;
          this.titleService.setTitle(pageTitle);
          
          // Create a description that includes key rider stats
          const description = `View ${this.rider.riderName}'s equestrian profile. From ${this.rider.country}, with ${this.wins} wins and ${this.podiums} podiums in ${this.classesEntered} competitions.`;
          
          // Update basic SEO meta tags
          this.metaService.updateTag({ name: 'description', content: description });
          
          // Update Open Graph tags specifically for better WhatsApp previews
          this.metaService.updateTag({ property: 'og:title', content: this.rider.riderName });
          this.metaService.updateTag({ property: 'og:site_name', content: 'Ecuestre Digital' });
          this.metaService.updateTag({ 
            property: 'og:description', 
            content: `Equestrian athlete from ${this.rider.country}. ${this.wins} wins, ${this.podiums} podiums in ${this.classesEntered} competitions.` 
          });
          
          // WhatsApp works best with square images
          if (this.rider.photo) {
            const optimizedImageUrl = this.rider.photo;
            this.metaService.updateTag({ property: 'og:image', content: optimizedImageUrl });
            this.metaService.updateTag({ property: 'og:image:width', content: '600' });
            this.metaService.updateTag({ property: 'og:image:height', content: '600' });
            this.metaService.updateTag({ property: 'og:image:alt', content: `${this.rider.riderName} - Equestrian Athlete` });
          }
          
          // Update Twitter Card tags
          this.metaService.updateTag({ name: 'twitter:title', content: pageTitle });
          this.metaService.updateTag({ name: 'twitter:description', content: description });
          if (this.rider.photo) {
            this.metaService.updateTag({ name: 'twitter:image', content: this.rider.photo });
          }
          
          // Add structured data for Google
          this.addStructuredData();
          
          this.podiums = result['podiums'];
          this.wins = result['wins'];
          this.classesEntered = result['resultsNum'];
          this.results = result['resultsData'];
          
          // Format dates for each result
          this.results.forEach(result => {
            if (result.date && !result.dateFormat) {
              result.dateFormat = this.formatDate(result.date);
            }
          });
          
          this.horses = result['horses'];
          this.filterHorses();
          
          // Calculate total pages and set up pagination
          this.totalPages = Math.ceil(this.horses.length / this.horsesPerPage);
          this.updatePaginatedHorses();
          
          // Initialize filteredResults with all results
          this.filteredResults = this.results;
          
          // Apply initial sort (newest first)
          this.sortColumn = 'date';
          this.sortDirection = 'desc';
          this.applySort();
          
          // Set loading to false after a short delay to ensure DOM is ready
          setTimeout(() => {
            this.isLoading = false;
          }, 300);

          // Create canonical URL
          const canonicalUrl = this.document.location.origin + this.document.location.pathname;
          const linkElement = this.document.createElement('link');
          linkElement.setAttribute('rel', 'canonical');
          linkElement.setAttribute('href', canonicalUrl);

          // Remove any existing canonical tag
          const existingCanonicalTag = this.document.querySelector('link[rel="canonical"]');
          if (existingCanonicalTag) {
            existingCanonicalTag.remove();
          }

          // Add the canonical tag to the head
          this.document.head.appendChild(linkElement);

          // Add language tag
          this.metaService.updateTag({ httpEquiv: 'content-language', content: 'en' });

          // Facebook app ID if you have one
          this.metaService.updateTag({ property: 'fb:app_id', content: '123456789012345' });

          // Twitter site username if you have one
          this.metaService.updateTag({ name: 'twitter:site', content: '@EcuestreDigital' });

          // Set the type to article for better preview formatting
          this.metaService.updateTag({ property: 'og:type', content: 'article' });

          // Ensure URL is properly set
          this.metaService.updateTag({ property: 'og:url', content: this.document.location.href });
        })
        .catch(error => {
          console.log('error', error);
          this.isLoading = false;
        });
    } catch (error) {
      console.error('Error loading rider data:', error);
      this.isLoading = false;
    }
  }

  changePage(direction: number): void {
    this.currentPage += direction;
    this.updatePaginatedHorses();
  }

  updatePaginatedHorses(): void {
    const startIndex = (this.currentPage - 1) * this.horsesPerPage;
    const endIndex = startIndex + this.horsesPerPage;
    this.paginatedHorses = this.horses.slice(startIndex, endIndex);
  }

  // public async getNewResults() {
  //   const resultadosMax = 50;
  //   const result_temp = await this.db.collection('results', ref => ref.where("riderId", "==", this.riderId )
  //     .orderBy("classDate", "desc")).get().toPromise().then(result => result.docs.map(d => d.data()));

  //   let result = [];
  //   result_temp.forEach((item:any) => {
  //     if ( item.result1 != "NP" && item.result1 != "" ){  // elimina los "NP" y participaciones pendientes
  //       result.push(item)
  //     }
  //   });
  //   this.resultsFb = result.slice(0, resultadosMax);
  //   console.log(this.resultsFb)
    
  //   this.resultsFb.forEach((item:any) => {
  //     let date = item.classDate.toDate().toLocaleDateString("es-MX")
  //     item.dateFormat = date
  //   });
  // }

  // public separateHorses(){

  //   let horse_checker = []
  //   let horses_filtered = []

  //   this.resultsFb.forEach(item => {
  //     if ( horse_checker.includes(item.horseName) ){  // evita caballos duplicados
  //       return
  //     }
  //     let horseInfo = item.horseInfo.replace(/\s+/g, ''); // quita espacios y diagonales
  //     if (horseInfo == "//") horseInfo = "";
  //     horse_checker.push(item.horseName)
  //     horses_filtered.push({horseId: item.horseId, horseName: item.horseName, horseInfo: horseInfo})
  //   });
    
  //   this.horsesFb = horses_filtered
  // }

  // public async getPruebas(ipcs: any) {
    
  //   let max = 10;
  //   let iteraciones = (ipcs.length / max);
  //   let resultado = [];
  //   let start = 0;
  //   let end = max;

  //   for (let i = 0; i < iteraciones; i++) {
  //     const ipcs_part = ipcs.slice(start, end);
  //     const pruebas = await this.db.collectionGroup('pruebas', ref => ref.where('ipc', "in", ipcs_part)).get().toPromise().then(
  //         pruebas => pruebas.docs.map(p => p.data())
  //       );

  //     resultado = resultado.concat(Object.values(pruebas));
  //     start += max
  //     end += max
  //   }
  //   return resultado
  // }

  // public getPruebaParam(ipc:number, param:string){

  //   if (this.pruebas.length == 0){
  //     console.log("vacio")
  //     return ""
  //   }

  //   let prueba = this.pruebas.find( p => p.ipc == ipc)
  //   console.log(ipc, prueba)
  //   return prueba[param]
  //   //return "1"
  // }

  calculateAge(dateOfBirth: string): number {
    if (!dateOfBirth) return 0;
    
    // First, try to split the date string in case it's in DD/MM/YYYY format
    const parts = dateOfBirth.split('/');
    let dob: Date;
    
    if (parts.length === 3) {
      // If date is in DD/MM/YYYY format
      dob = new Date(+parts[2], +parts[1] - 1, +parts[0]);
    } else {
      // Try direct parsing
      dob = new Date(dateOfBirth);
    }

    // Check if date is valid
    if (isNaN(dob.getTime())) {
      console.log('Invalid date format:', dateOfBirth);
      return 0;
    }

    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    
    return age;
  }

  calculateHorseAge(dateOfBirth: string): number {
    if (!dateOfBirth) return 0;
    
    // Handle DD/MM/YYYY format
    const parts = dateOfBirth.split('/');
    let birthYear: number;
    
    if (parts.length === 3) {
      // If date is in DD/MM/YYYY format
      birthYear = parseInt(parts[2], 10);
    } else {
      // Try direct parsing
      const date = new Date(dateOfBirth);
      if (isNaN(date.getTime())) {
        console.log('Invalid horse date format:', dateOfBirth);
        return 0;
      }
      birthYear = date.getFullYear();
    }
    
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  }

  toggleHorsesView(): void {
    this.showAllHorses = !this.showAllHorses;
  }

  setCategory(categoryId: string): void {
    this.currentCategory = categoryId;
    this.filterHorses();
  }

  filterHorses(): void {
    switch(this.currentCategory) {
      case 'recent':
        // Assuming the horses are already sorted by most recently ridden
        this.filteredHorses = this.horses.slice(0, 10);
        break;
      default:
        this.filteredHorses = this.horses;
    }
  }

  getCategoryCount(categoryId: string): number {
    switch(categoryId) {
      case 'recent':
        return Math.min(10, this.horses.length);
      default:
        return this.horses.length;
    }
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';
    
    // Check if the date is in DD/MM/YYYY format
    const parts = dateString.split('/');
    if (parts.length === 3) {
      // Format as Month Day, Year
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // JS months are 0-indexed
      const year = parseInt(parts[2], 10);
      
      const date = new Date(year, month, day);
      
      // Check if date is valid
      if (!isNaN(date.getTime())) {
        return date.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric', 
          year: 'numeric' 
        });
      }
    }
    
    // Try to parse as a regular date string
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric', 
        year: 'numeric' 
      });
    }
    
    // Return the original string if parsing fails
    return dateString;
  }

  filterResultsByHorse(horseId: string | null): void {
    if (!horseId) {
      // If no horse is selected, show all results
      this.filteredResults = this.results;
    } else {
      // Filter results to only show those for the selected horse
      this.filteredResults = this.results.filter(result => 
        result.horseId === horseId
      );
    }
    
    // Apply the current sort to the filtered results
    this.applySort();
  }

  selectHorse(event: Event, horseId: string): void {
    event.preventDefault(); // Prevent the default link behavior
    
    if (this.selectedHorseId === horseId) {
      // If clicking the same horse again, clear the filter
      this.selectedHorseId = null;
    } else {
      // Otherwise, set the selected horse
      this.selectedHorseId = horseId;
    }
    
    // Apply the filter
    this.filterResultsByHorse(this.selectedHorseId);
  }

  // Get the name of the selected horse
  getSelectedHorseName(): string {
    if (!this.selectedHorseId) return '';
    
    const horse = this.horses.find(h => h.id === this.selectedHorseId);
    return horse ? horse.name : '';
  }

  // Clear the horse filter
  clearHorseFilter(): void {
    this.selectedHorseId = null;
    this.filterResultsByHorse(null);
  }

  sortResults(column: string): void {
    // If clicking the same column, toggle direction
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // If clicking a new column, set it as the sort column and set default direction
      this.sortColumn = column;
      // Default to ascending for rank, descending for date
      this.sortDirection = column === 'rank' ? 'asc' : 'desc';
    }
    
    // Apply the sort
    this.applySort();
  }

  applySort(): void {
    this.filteredResults = [...this.filteredResults].sort((a, b) => {
      let valueA: any;
      let valueB: any;
      
      // Extract the values to compare based on the sort column
      switch (this.sortColumn) {
        case 'date':
          // Try to parse dates for comparison
          valueA = this.parseDate(a.date);
          valueB = this.parseDate(b.date);
          break;
        case 'event':
          valueA = a.showName?.toLowerCase() || '';
          valueB = b.showName?.toLowerCase() || '';
          break;
        case 'class':
          valueA = a.className?.toLowerCase() || '';
          valueB = b.className?.toLowerCase() || '';
          break;
        case 'horse':
          valueA = (a.horseName || a.horse)?.toLowerCase() || '';
          valueB = (b.horseName || b.horse)?.toLowerCase() || '';
          break;
        case 'rank':
          valueA = parseInt(a.rank, 10) || 999;
          valueB = parseInt(b.rank, 10) || 999;
          break;
        default:
          return 0;
      }
      
      // Compare the values
      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      
      // Reverse if descending
      return this.sortDirection === 'desc' ? comparison * -1 : comparison;
    });
  }

  parseDate(dateString: string): Date {
    if (!dateString) return new Date(0); // Default to epoch
    
    // Try DD/MM/YYYY format first
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    }
    
    // Fall back to standard date parsing
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? new Date(0) : date;
  }

  // Add a method to create structured data for Google
  private addStructuredData(): void {
    if (!this.rider) return;
    
    setTimeout(() => {
      try {
        // Remove any existing structured data
        const existingScripts = this.document.querySelectorAll('script[type="application/ld+json"]');
        existingScripts.forEach(script => script.remove());
        
        // Create a single comprehensive schema with explicit IDs and names
        const schema = {
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'Person',
              '@id': this.document.location.href + '#person',
              'name': this.rider.riderName,
              'givenName': this.rider.riderName.split(' ').slice(0, -1).join(' '), // Attempt to extract first name
              'familyName': this.rider.riderName.split(' ').slice(-1)[0], // Attempt to extract last name
              'nationality': this.rider.country,
              'image': this.rider.photo || '',
              'birthDate': this.rider.dob || '',
              'affiliation': this.rider.clubName || '',
              'url': this.document.location.href,
              'sameAs': [
                // Add social media profiles if available
                // 'https://www.facebook.com/riderprofile',
                // 'https://www.instagram.com/riderprofile'
              ]
            },
            {
              '@type': 'BreadcrumbList',
              '@id': this.document.location.href + '#breadcrumb',
              'name': 'Navigation Path',
              'itemListElement': [
                {
                  '@type': 'ListItem',
                  'position': 1,
                  'name': 'Home',
                  'item': this.document.location.origin
                },
                {
                  '@type': 'ListItem',
                  'position': 2,
                  'name': 'Rider Profiles',
                  'item': `${this.document.location.origin}/rider-profiles`
                },
                {
                  '@type': 'ListItem',
                  'position': 3,
                  'name': this.rider.riderName,
                  'item': this.document.location.href
                }
              ]
            },
            {
              '@type': 'ProfilePage',
              '@id': this.document.location.href,
              'name': `${this.rider.riderName} - Rider Profile | Ecuestre Digital`,
              'headline': `${this.rider.riderName} - Equestrian Athlete Profile`,
              'description': `View ${this.rider.riderName}'s equestrian profile. From ${this.rider.country}, with ${this.wins} wins and ${this.podiums} podiums in ${this.classesEntered} competitions.`,
              'isPartOf': {
                '@type': 'WebSite',
                'name': 'Ecuestre Digital',
                'url': this.document.location.origin
              },
              'about': {
                '@id': this.document.location.href + '#person'
              },
              'mainEntity': {
                '@id': this.document.location.href + '#person'
              },
              'inLanguage': 'en',
              'datePublished': new Date().toISOString().split('T')[0],
              'dateModified': new Date().toISOString().split('T')[0]
            }
          ]
        };
        
        // Add competition data if available
        if (this.results && this.results.length > 0) {
          const personSchema = schema['@graph'][0];
          personSchema['award'] = `${this.wins} wins and ${this.podiums} podiums in equestrian competitions`;
          
          // Add some sample competitions
          const sampleCompetitions = this.results.slice(0, 3).map(result => ({
            '@type': 'SportsEvent',
            'name': result.className || 'Equestrian Competition',
            'location': {
              '@type': 'Place',
              'name': result.showName || 'Competition Venue'
            },
            'startDate': result.date || '',
            'competitor': {
              '@type': 'Person',
              'name': this.rider.riderName
            }
          }));
          
          if (sampleCompetitions.length > 0) {
            personSchema['performerIn'] = sampleCompetitions;
          }
        }
        
        // Add the schema to the page
        const script = this.document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
        this.document.head.appendChild(script);
        
        console.log('Enhanced structured data added with explicit names and IDs');
      } catch (error) {
        console.error('Error adding structured data:', error);
      }
    }, 500);
  }

  getWhatsAppShareUrl(): string {
    const text = `Check out ${this.rider?.riderName}'s equestrian profile on Ecuestre Digital!`;
    const url = this.document.location.href;
    return `https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`;
  }
}
