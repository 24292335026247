import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit, AfterViewInit {
  public concursos: any[];
  public zoneButtons: any;
  public activeButton: any;
  public zone: any;


  constructor(private db: AngularFirestore) {
    this.concursos = [];
    this.zoneButtons = '';
    this.zone = 'all';
  }

  ngOnInit() {
    this.getShows();
  }

  ngAfterViewInit() {
    let navButtons: any = document.getElementsByClassName('nav-link active');
    navButtons.forEach(element => {
      element.classList.remove('active');
    });

    document.getElementById('archive').classList.add('active');

  }

  public getShows() {
    this.db.collection('concursos', ref => ref.orderBy('fin', 'desc').where('fin', '<', new Date())).valueChanges().subscribe(
      concursos => {
        this.concursos = concursos.map((c: any) => {
          c.inicio = c.inicio.toDate();
          c.fin = c.fin.toDate();
          return c;
        });
      }
    );
  }

  public getFilteredShows() {
    if (this.zone == 'all') {
      return this.concursos;
    }

    return this.concursos.filter((concurso) => {
      return concurso.zona == this.zone;
    });

  }

  public setZone(zone) {
    this.zone = zone;
    //console.log('Setting to zone: ' + zone);
    this.getFilteredShows();
    this.zoneButtons = document.getElementsByClassName('btn-dark');
    this.zoneButtons.forEach(element => {
      element.classList.remove('btn-dark');
      element.classList.add('btn-light');
    });
    this.activeButton = document.getElementById(zone);
    this.activeButton.classList.add('btn-dark');
    this.activeButton.classList.remove('btn-light');

  }

}
