import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntryClassesService {

  constructor(private http: HttpClient) {

  }


  public getEntryClasses(entry, showId): Observable<any> {
    let url = '';
    if (showId.includes('us_')) {
      url = 'https://api2.equestrian.digital/web/entry/classes/';
      showId = showId.substring(3);
    }
    const params = JSON.stringify({ entry: entry, showId: showId });
    const headers = {
      'x-api-key': '7cc263a1-a490-4337-8302-151490495e56',
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(url, params, { headers: headers });
  }

}
