//import { utf8Encode } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { PersonService } from './../services/person.service';
import { DocumentData } from 'firebase/firestore';

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.scss']
})
export class CompetitionComponent implements OnInit {
  public showId: any;
  private showInfo: any;
  public competitionId: any;
  public competition: any;
  public results: any;
  public teams: any;
  public youtubeId: any;
  public youtubeEmbed: any;
  public init: boolean;
  public categorias: any[];
  public categoriaSeleccionada: string;
  public isUS: boolean = false;

  constructor(
    private route: ActivatedRoute, private db: AngularFirestore,
    private _personService: PersonService
  ) {
    this.competitionId = this.route.snapshot.paramMap.get('competitionId');
    this.showId = this.route.snapshot.paramMap.get('showId');
    this.results = [];
    this.teams = [];
    this.init = true;
    this.categorias = [];
    this.categoriaSeleccionada = '';
    this.isUS = this.competitionId.includes('us_');
  }

  ngOnInit() {
    this.getCompetition();
    //this.getResults();
  }

  async getCompetition() {
    this.showInfo = await this.db.collection('concursos').doc(this.showId).get().toPromise().then(
      (doc) => doc.data()
    );
    console.log(this.showInfo)
    this.db.collection('concursos').doc(this.showId).collection('pruebas').doc(this.competitionId).valueChanges().subscribe(competition => {
      if (!competition) return;
      if (competition.agrupadores.length > 1) {
        this.categorias = [{ nombre: 'Sin Filtrar', value: '' }, ...competition.agrupadores.map(a => ({ nombre: a, value: a }))];
      }
      this.competition = competition;
      let n = competition['youtube_url'].lastIndexOf('/');
      this.youtubeId = competition['youtube_url'].substring(n + 1).trim();
      this.youtubeEmbed = ('https://www.youtube.com/embed/' + this.youtubeId).trim();
      if (this.init) {
        this.init = false;
        this.getResults();
      }
    });
  }

  public getResults() {
    this.db.collection('results', ref => ref.where("ipc", "==", this.competitionId).orderBy('order')).valueChanges().subscribe(results => {
      if (this.competition.id_modalidad == '71') {
        //this.teams = results.filter((r:any) => r.teamName).sort( (a:any,b:any) => a.rank - b.rank);
        this.teams = results.filter((r: any) => r.teamName);
      } else {
        this.results = results;
      }
      const [inRing] = results.filter((r: any) => r.inRing);
      console.log('inRing', inRing)
    });
  }

  public encodeClip(string: string) {
    //return btoa(utf8Encode(string).toString());
    return btoa(string);
  }

  public filteredResults() {
    if (!this.categoriaSeleccionada) return this.results;

    return this.results.filter(r => r.riderCategory == this.categoriaSeleccionada);
  }

  isValidYoutubeUrl(url: string): boolean {
    const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    return pattern.test(url);
  }

  async makecsv() {
    let has2Rounds: boolean = false;
    let licencias: any = {};
    let csv = `" ",${this.competition.numero},${this.competition.nombre}\n`;
    let title = `Puesto,Dorsal,LDN,Nombre, Apellidos,microchip,LAC,Caballo,Puntos obstaculos,Puntos tiempo, tiempo`;
    // obtiene las licencias de los binomios
    if (this.showInfo.zona == 'espana') {
      try {
        licencias = await this._personService.getLicencias(this.showId).toPromise();
      } catch (error) { console.log(error) }
    }

    //formas filas del CSV
    this.results.forEach((result: any) => {
      if (this.showInfo.zona == 'espana') {
        let round2CSV = ''; // segunda ronda
        const time = result.result1 ? result.result1.replace(' ', '').split('|')[1] : ''; // tiempo de la primera ronda
        const fault = result.result1 ? result.result1.replace(' ', '').split('|')[0] : ''; // faltas de la primera ronda
        const timeFaults: any = (this.competition.tiempo_acordado > time) ? '0' : Math.ceil(time - this.competition.tiempo_acordado) || '-'; // faltas por tiempo
        const jumpFaults = (fault > 0) ? fault - timeFaults : '0'; // faltas por obstaculos
        const lic = licencias.data.find(l => l.id_binomio == result.id) || ''; // Licencia del binomio
        // si la competencia tiene dos rondas
        if (!has2Rounds && result.result2 != '') {
          has2Rounds = true;
          csv += `${title},Puntos obstaculos 2, Puntos tiempo 2, tiempo 2, premio\n`;
        } else if (!has2Rounds && result.result2 == '' && csv.indexOf('premio') === -1) {
          csv += `${title},premio\n`;
        }

        if (result.result2 != '') {
          const time2 = result.result2 ? result.result2.replace(' ', '').split('|')[1] : '';
          const faults2 = result.result2 ? result.result2.replace(' ', '').split('|')[0] : '';
          const timeFaults2:any = (this.competition.tiempo_acordado_2 > time2) ? '0' : Math.ceil(time2 - this.competition.tiempo_acordado_2);
          const jumpFaults2 = (faults2 > 0) ? faults2 - timeFaults : ' 0 ' ;
          round2CSV = `,${this.escapedValues(jumpFaults2.toString())},${this.escapedValues(timeFaults2.toString())},${this.escapedValues(time2.toString())}`
        } else if (has2Rounds) {
          round2CSV = ',,,';
        }

        const { name, lastname } = this.getNameParts(result.riderName);

        csv += result.rank + ',' + // PUESTO
          result.entryNumber + ',' + // DORSAL
          lic.LDN + ',' + // LDN
          this.escapedValues(name) + ',' + // NOMBRE / APELLIDOS
          this.escapedValues(lastname) + ',' + // NOMBRE / APELLIDOS
          ',' + // MICROCHIP
          lic.LCA + ',' + // LAC
          this.escapedValues(result.horseName) + ',' + //CABALLO
          (this.escapedValues(jumpFaults.toString()) || '') + ',' + // PUNTOS OBSTACULOS
          (this.escapedValues(timeFaults.toString()) || '') + ',' + // PUNTOS TIEMPO
          (this.escapedValues(result.time) || '') + // TIEMPO
          round2CSV + ',' +
          (this.escapedValues(result.prize) || '') + ',' + // LAC
          '\n';
      } else {
        csv += result.rank + ',' + result.entryNumber + ',' + this.escapedValues(result.riderName) + ',' + this.escapedValues(result.horseName) + ',' + result.riderAcronym + ',' + this.escapedValues(result.owner) + '\n';
      }
    });
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = this.competition.numero + ' - ' + this.competition.nombre + '.csv';
    link.click();
    window.URL.revokeObjectURL(url);
    return csv;
  }

  public escapedValues(value) {
    if (typeof value === 'string' && value.includes(',')) {
      return `"${value}"`;
    }
    return value;
  }

  public getNameParts(fullname: string) {
    const parts = fullname.trim().split(/\s+/);
    if (parts.length < 2) return { name: parts[0], lastname: '' };
    if (parts.length === 2) return { name: parts[0], lastname: parts[1] };
    return {
      name: parts.slice(0, -2).join(" "),
      lastname: parts.slice(-2).join(" ")
    }
  }
}
