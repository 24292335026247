import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Firestore, collection, query, getDocs, orderBy } from '@angular/fire/firestore';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChampionshipService {

  constructor(private http: HttpClient, private firestore: Firestore) {
  }

  public async getChampionships(showId: string): Promise<any> {
    const championships = query(collection(this.firestore, 'concursos', showId, 'championships'), orderBy('order'));
    return getDocs(championships).then(snapshot => snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  }

  public getSpreadsheetInfo(APIKey: string, spreadsheetId: string): Promise<any> {
    const params = {
      key: APIKey
    };
    return firstValueFrom(this.http.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`, { params })).catch(err => ({ error: err.error.error.message||'' }));
  }

  public getSpreadsheetData(APIKey: string, spreadsheetId: string, sheetName: string = 'Sheet1', cellRange?: string): Promise<any> {
    const params = {
      key: APIKey
    };
    return firstValueFrom(this.http.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}${cellRange ? ('!'+cellRange) : ''}`, { params }).pipe(map((res: any) => res.values))).catch(err => ({ error: err.error.error.message||'' }));
  }
}
