import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-horse-profile',
  templateUrl: './horse-profile.component.html',
  styleUrls: ['./horse-profile.component.scss']
})
export class HorseProfileComponent implements OnInit {
  public horseId: any;
  public horse: any;
  public riders: any;
  public podiums: any;
  public classesEntered: any;
  public wins: any;
  public results: any;
  public prefix: string;

  constructor(private route: ActivatedRoute, private titleService: Title) {
    this.horseId = this.route.snapshot.paramMap.get('horseId');
    this.titleService.setTitle("Horse Profile");
  }

  ngOnInit() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "7cc263a1-a490-4337-8302-151490495e56");
    myHeaders.append("Content-Type", "application/json");
    let url = "https://api.ecuestre.digital/profiles/horse/";

    if (this.horseId.includes('us_')) {
      console.log('US Horse');
      url = 'https://api.equestrian.digital/profiles/horse/';
      this.horseId = this.horseId.replace('us_', '');
      this.prefix = 'us_';
    }

    var raw = JSON.stringify({
      "horseId": this.horseId
    });

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.horse = result['horseInfo'];
        this.titleService.setTitle(this.horse.horseName + " - " + "Horse Profile");
        this.podiums = result['podiums'];
        this.wins = result['wins'];
        this.classesEntered = result['resultsNum'];
        this.results = result['resultsData'];
        this.riders = result['riders'];

      })
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

}

