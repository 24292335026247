import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-clip-viewer',
  templateUrl: './clip-viewer.component.html',
  styleUrls: ['./clip-viewer.component.scss']
})
export class ClipViewerComponent implements OnInit {
  public clipId: any;

  constructor(private route: ActivatedRoute) {
    this.clipId = atob(this.route.snapshot.queryParams['clip']);
    //this.clipId = this.route.snapshot.paramMap.get('clip');
  }

  ngOnInit() {
  }

}
