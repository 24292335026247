import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';

interface ClassResult {
  classId: number;
  className: string;
  clubLogo: string;
  showName: string;
  classNumber: string;
  points: number;
}

interface Standing {
  binomio: {
    horse: { name: string };
    id: number;
    rider: { name: string };
  };
  binomioId: number;
  classResults: ClassResult[];
  classesParticipated: number;
  horseName: string;
  position: number;
  riderName: string;
  totalPoints: number;
}

interface Circuit {
  club_id: number;
  club: {
    id: number;
    name: string;
  };
  id: number;
  name: string;
  points_system: string;
  season: number;
  //standings: Standing[]; 
}

interface ClassInfo {
  classNumber: string;
  className: string;
  clubLogo: string;
  showName: string;
  classId: string;
  showId: string;
}

@Component({
  selector: 'app-circuit-detail',
  templateUrl: './circuit-detail.component.html',
  styleUrls: ['./circuit-detail.component.css']
})
export class CircuitDetailComponent implements OnInit {
  circuit: any;
  loading = true;
  error: string | null = null;
  uniqueClasses: ClassInfo[] = [];

  constructor(
    private route: ActivatedRoute,
    private firestore: Firestore
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const { circuit_id } = params;
      this.fetchCircuitDetail(circuit_id);
    });
  }

  private async fetchCircuitDetail(circuitId: string): Promise<void> {
    try {
      console.log('Fetching circuit:', circuitId);
      // Create a compound ID that matches your document structure
      const docId = circuitId;
      const docRef = doc(this.firestore, 'circuits', docId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        //console.log('Document data:', data);
        this.circuit = data;
        this.extractUniqueClassIds();
      } else {
       // console.log('Document does not exist');
        this.error = 'Circuit not found';
      }
    } catch (error) {
      console.error('Error fetching circuit:', error);
      this.error = 'Error loading circuit details';
    } finally {
      this.loading = false;
    }
  }

  private extractUniqueClassIds(): void {
    // Create a map to store unique classes with their full info
    const classMap = new Map<string, ClassInfo>();
    //console.log('Classmap: ', classMap);
    
    this.circuit.standings.forEach((standing: any) => {
      console.log('Standing:', standing);
      standing.classResults.forEach((result: any) => {
        //console.log('Result:', result);
        if (!classMap.has(result.classId)) {
          classMap.set(result.classId, {
            classNumber: result.classNumber,
            className: result.className,
            clubLogo: result.clubLogo,
            showName: result.showName,
            classId: result.classId,
            showId: result.showId
          });
        }
      });
    });
    
    // Convert map to array and sort by class number
    this.uniqueClasses = Array.from(classMap.values())
      .sort((a, b) => a.classId.toString().localeCompare(b.classId.toString()));
  }

  getPointsForClass(standing: any, classId: string): string {
    const classResult = standing.classResults.find(
      (result: any) => result.classId === classId
    );
    return classResult ? classResult.points.toString() : '-';
  }
} 