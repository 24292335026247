<section class="py-4">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="padding: 10px;">
          <h3 class="header-title mb-3">Filter By Zone</h3>

          <div class="btn-group">
            <button class="btn btn-dark" id='all' (click)="setZone('all')">ALL</button>
            <button class="btn btn-light" id='usa' (click)="setZone('usa')">US & CAN</button>
            <button class="btn btn-light" id='mexico' (click)="setZone('mexico')">MEX</button>
            <button class="btn btn-light" id='america' (click)="setZone('america')">C/S AME</button>
            <button class="btn btn-light" id='espana' (click)="setZone('espana')">SPAIN</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4" *ngFor="let concurso of getFilteredShows()">
        <a href="/show/{{concurso.id}}" style="color: #838f9c;">
          <div class="card">
            <img class="card-img-top" [src]="concurso.banner" alt="Banner concurso">
            <div class="card-body">
              <div class="row">
                <div class="col-2" style="padding: 0;">
                  <img [src]="concurso.logo_url" alt="" style="max-width: 100%; max-height: 50px;">
                </div>
                <div class="col-7">
                  <h5 class="card-title">{{ concurso.nombre }}</h5>
                </div>
                <div class="col-3 text-center">
                  <strong>{{ concurso.inicio | date: 'MMM' }}</strong>
                  <br>
                  {{ concurso.inicio | date: 'dd' }} - {{ concurso.fin | date: 'dd' }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
