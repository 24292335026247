<!-- NAVBAR START -->
<nav class="navbar navbar-expand-lg py-lg-3 navbar-dark"
  style="background-image: linear-gradient(to bottom,#15171B,#0C0D11);">
  <div class="container">

    <!-- logo -->
    <a href="index.html" class="navbar-brand mr-lg-4">
      <img src="assets/images/logo-ed.png" alt="" class="logo-dark" height="40" />
      <span style="padding: 5px; margin-left:10px;">Equestrian Digital</span>
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <i class="mdi mdi-menu"></i>
    </button>

    <!-- menus -->
    <div class="collapse navbar-collapse" id="navbarNavDropdown">

      <!-- left menu -->
      <ul class="navbar-nav mr-auto align-items-center">
        <li class="nav-item mx-lg-1">
          <a class="nav-link" href="/" id='shows'>CURRENT SHOWS</a>
        </li>
        <li class="nav-item mx-lg-1">
          <a class="nav-link" href="/archive" id="archive">SHOW ARCHIVE</a>
        </li>
        <!-- <li class="nav-item mx-lg-1">
          <a class="nav-link" href="/rider-profile">RIDERS</a>
        </li>
        <li class="nav-item mx-lg-1">
          <a class="nav-link" href="">Horses</a>
        </li>
        <li class="nav-item mx-lg-1">
          <a class="nav-link" href="">Contact</a>
        </li>-->
        <li class="nav-item mx-lg-1">
          <a class="nav-link" href="/info" id="info">INFO</a>
        </li>
        <li class="nav-item mx-lg-1 autocomplete">
          <div class="input-group">
            <div class="app-search dropdown d-lg-block">
              <span class="mdi mdi-magnify search-icon"></span>
              <input type="text" id="search_input_riders" name="search_input_riders" class="form-control" placeholder="Riders" (keyup)="onSearchRidersKeyUp($event)">
            </div>
          </div>
          
          <div class="autocomplete-items">
            <ng-container  *ngFor="let jinete of jinetesMostrar">
              <div (click)="viewRider( jinete.id_persona )" > {{ jinete.name }} {{ jinete.lastname}} </div>
            </ng-container>
          </div>
        </li>

        <li class="nav-item mx-lg-1 autocomplete">
          <div class="input-group">
            <div class="app-search dropdown d-lg-block">
              <span class="mdi mdi-magnify search-icon"></span>
              <input type="text" id="search_input_horses" name="search_input_horses" class="form-control" placeholder="Horses" (keyup)="onSearchHorsesKeyUp($event)">
            </div>
          </div>

          <div class="autocomplete-items">
            <ng-container  *ngFor="let caballo of caballosMostrar">
              <div (click)="viewHorse( caballo.id_caballo )" > {{ caballo.fullname }}  </div>
            </ng-container>
          </div>
        </li>

      </ul>

      <!-- right menu -->
      <!-- <ul class="navbar-nav ml-auto align-items-center">
        <li class="nav-item mr-0">
          <a href="https://themes.getbootstrap.com/product/hyper-responsive-admin-dashboard-template/" target="_blank"
            class="nav-link d-lg-none">About ED</a>
          <a href="https://themes.getbootstrap.com/product/hyper-responsive-admin-dashboard-template/" target="_blank"
            class="btn btn-sm btn-light btn-rounded d-none d-lg-inline-flex">
            About ED
          </a>
        </li>
      </ul> -->

    </div>
  </div>
</nav>
<!-- NAVBAR END -->
