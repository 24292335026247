<div class="circuits-container">
  <div *ngIf="loading" class="loading">
    <div class="loading-spinner"></div>
    <span>Loading circuits...</span>
  </div>

  <div *ngIf="!loading" class="page-header">
    <div class="header-content">
      <div class="header-title">
        <div class="club-info">
          <h1>{{ clubName }}</h1>
          <div class="season-tag">Season {{ season }}</div>
        </div>
        <p class="header-subtitle">Circuit Standings</p>
      </div>
      <div class="header-stats">
        <div class="stat-box">
          <span class="stat-value">{{ circuits.length }}</span>
          <span class="stat-label">Active Circuits</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loading" class="circuits-grid">
    <div *ngFor="let circuit of circuits" 
         class="circuit-card"
         [routerLink]="['/circuits', club_id, season, circuit.id]">
      <div class="card-content">
        <div class="card-header">
          <h3>{{ circuit.name }}</h3>
          <span class="participants-count">{{ circuit.standings?.length || 0 }} participants</span>
        </div>
        <div class="card-details">
          <span class="detail-item">
            <i class="fas fa-trophy"></i>
            {{ circuit.points_system }}
          </span>
          <span class="detail-item">
            <i class="fas fa-calendar"></i>
            {{ circuit.updated_at | date:'mediumDate' }}
          </span>
        </div>
      </div>
      <div class="card-arrow">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>

    <div *ngIf="!loading && circuits.length === 0" class="no-circuits">
      <i class="fas fa-search"></i>
      <h3>No circuits found</h3>
      <p>No circuits available for this club and season.</p>
    </div>
  </div>
</div>
