import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { collectionGroup, query, where, } from "firebase/firestore";  

@Component({
  selector: 'app-rider-profile',
  templateUrl: './rider-profile.component.html',
  styleUrls: ['./rider-profile.component.scss']
})
export class RiderProfileComponent implements OnInit {
  public riderId: any;
  public rider: any;
  public podiums: any;
  public classesEntered: any;
  public wins: any;
  public results: any;
  public horses: any;
  public prefix: string;
  public resultsFb: any;
  public horsesFb: any;
  public pruebas: any;
  public ipcs: any;


  constructor(private route: ActivatedRoute, private titleService: Title, private metaService: Meta, private db: AngularFirestore) {
    this.riderId = this.route.snapshot.paramMap.get('riderId');
    this.titleService.setTitle('ED - Rider Profile');
    this.metaService.updateTag(
      {
        name: 'title', content: 'ED - Rider Profile.',
      });
    this.metaService.updateTag(
      {
        name: 'og:title', content: 'ED - Rider Profile.',
      });

    this.metaService.updateTag(
      {
        name: 'og:description', content: 'Detailed profile with rider info and results history.',
      });
    this.metaService.updateTag(
      {
        name: 'description', content: 'Detailed profile with rider info and results history.',
      });
    this.pruebas = []
  }



  async ngOnInit() {

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "7cc263a1-a490-4337-8302-151490495e56");
    myHeaders.append("Content-Type", "application/json");
    let url = 'https://api.ecuestre.digital/profiles/rider/';



    if (this.riderId.includes('us_')) {
      console.log('US Rider');
      url = 'https://api.equestrian.digital/profiles/rider/';
      this.riderId = this.riderId.replace('us_', '');
      this.prefix = 'us_';
    }

    var raw = JSON.stringify({
      "riderId": this.riderId
    });

    console.log(this.riderId);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      //fetch("https://j7iuf3kjzc.execute-api.us-west-1.amazonaws.com/Production", requestOptions)
      .then(response => response.json())
      .then(result => {
        //result = JSON.parse(result);
        this.rider = result['riderInfo'];
        this.titleService.setTitle(this.rider.riderName + " - " + "Rider Profile");
        this.podiums = result['podiums'];
        this.wins = result['wins'];
        this.classesEntered = result['resultsNum'];
        this.results = result['resultsData'];
        this.horses = result['horses'];
        //console.log(result)
      })
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

      await this.getNewResults()
      this.separateHorses()
  }

  public async getNewResults() {
    const resultadosMax = 50;
    const result_temp = await this.db.collection('results', ref => ref.where("riderId", "==", this.riderId )
      .orderBy("classDate", "desc")).get().toPromise().then(result => result.docs.map(d => d.data()));

    let result = [];
    result_temp.forEach((item:any) => {
      if ( item.result1 != "NP" && item.result1 != "" ){  // elimina los "NP" y participaciones pendientes
        result.push(item)
      }
    });
    this.resultsFb = result.slice(0, resultadosMax);
    console.log(this.resultsFb)
    
    this.resultsFb.forEach((item:any) => {
      let date = item.classDate.toDate().toLocaleDateString("es-MX")
      item.dateFormat = date
    });
  }

  public separateHorses(){

    let horse_checker = []
    let horses_filtered = []

    this.resultsFb.forEach(item => {
      if ( horse_checker.includes(item.horseName) ){  // evita caballos duplicados
        return
      }
      let horseInfo = item.horseInfo.replace(/\s+/g, ''); // quita espacios y diagonales
      if (horseInfo == "//") horseInfo = "";
      horse_checker.push(item.horseName)
      horses_filtered.push({horseId: item.horseId, horseName: item.horseName, horseInfo: horseInfo})
    });
    
    this.horsesFb = horses_filtered
  }

  public async getPruebas(ipcs: any) {
    
    let max = 10;
    let iteraciones = (ipcs.length / max);
    let resultado = [];
    let start = 0;
    let end = max;

    for (let i = 0; i < iteraciones; i++) {
      const ipcs_part = ipcs.slice(start, end);
      const pruebas = await this.db.collectionGroup('pruebas', ref => ref.where('ipc', "in", ipcs_part)).get().toPromise().then(
          pruebas => pruebas.docs.map(p => p.data())
        );

      resultado = resultado.concat(Object.values(pruebas));
      start += max
      end += max
    }
    return resultado
  }

  public getPruebaParam(ipc:number, param:string){

    if (this.pruebas.length == 0){
      console.log("vacio")
      return ""
    }

    let prueba = this.pruebas.find( p => p.ipc == ipc)
    console.log(ipc, prueba)
    return prueba[param]
    //return "1"
  }
}
