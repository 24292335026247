<div class="profile-container">
  <!-- Simple Loading Indicator -->
  <div *ngIf="isLoading" class="loading-indicator">
    <div class="spinner"></div>
    <p>Loading rider profile...</p>
  </div>

  <!-- Actual Content - Only show when not loading -->
  <div [ngClass]="{'content-loaded': !isLoading, 'content-hidden': isLoading}">
    <!-- Hero Section -->
    <div class="hero-section">
      <div class="hero-content">
        <div class="rider-identity">
          <div class="rider-photo" *ngIf="rider?.photo">
            <img [src]="rider?.photo" [alt]="rider?.riderName">
          </div>
          <div class="rider-info">
            <div class="country-flag">
              <img [src]="rider?.flag" [alt]="rider?.country">
              <span class="country-code">{{rider?.countryLetters}}</span>
            </div>
            <h1>
              {{rider?.riderName}}
              <div class="rider-details">
                <span class="rider-age" *ngIf="rider?.dob">{{calculateAge(rider?.dob)}} years old</span>
                <span class="rider-dob" *ngIf="rider?.dob">(Born {{rider?.dob}})</span>
              </div>
            </h1>
            <div class="rider-subtitle">{{rider?.clubName}}</div>
            
            <!-- Key stats -->
            <div class="key-stats">
              <div class="stat-card">
                <div class="stat-value">{{wins}}</div>
                <div class="stat-label">Wins</div>
                <div class="stat-percentage">{{(wins / classesEntered) | percent}}</div>
              </div>
              <div class="stat-card">
                <div class="stat-value">{{podiums}}</div>
                <div class="stat-label">Podiums</div>
                <div class="stat-percentage">{{(podiums / classesEntered) | percent}}</div>
              </div>
              <div class="stat-card">
                <div class="stat-value">{{classesEntered}}</div>
                <div class="stat-label">Classes Entered</div>
              </div>
              <div class="stat-card" *ngIf="rider?.feiRanking">
                <div class="stat-value">{{rider?.feiRanking || 'N/A'}}</div>
                <div class="stat-label">FEI Ranking</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- After the Bio Section and before Recent Events -->
    <div class="horses-section">
      <h2>Horses Ridden ({{horses.length}})</h2>
      
      <div class="horses-tabs">
        <button 
          *ngFor="let category of horseCategories" 
          [class.active]="currentCategory === category.id"
          (click)="setCategory(category.id)" 
          class="tab-button">
          {{category.name}} ({{getCategoryCount(category.id)}})
        </button>
      </div>
      
      <div class="horses-grid">
        <ng-container *ngFor="let horse of filteredHorses">
          <a (click)="selectHorse($event, horse.id)" 
             [class.selected]="selectedHorseId === horse.id"
             class="horse-card">
            <div class="horse-details">
              <h4>{{horse.name}}</h4>
              <div class="horse-info" *ngIf="horse.dob && horse.dob !== '00/00/0000'">
                {{calculateHorseAge(horse.dob)}} years old
              </div>
            </div>
          </a>
        </ng-container>
      </div>
    </div>

    <!-- Then Recent Events and Latest Results sections TEMPORARILY DISABLED-->
    <!-- <div class="recent-events">
      <h2>Recent Events</h2>
      <div class="events-grid">
        <div class="event-card" *ngFor="let result of results | slice:0:4">
          <a [href]="'/competition/' + prefix + '0/' + prefix + result.ipc">
            <img [src]="result.showBanner" [alt]="result.showName">
            <div class="event-details">
              <div class="event-name">{{result.showName}}</div>
              <div class="event-class">{{result.height}} {{result.className}}</div>
              <div class="event-date">{{result.dateFormat}}</div>
            </div>
          </a>
        </div>
      </div>
    </div> -->

    <!-- Results & Horses Section -->
    <div class="results-section">
      <div class="results-header">
        <h2>Latest Results</h2>
        <div *ngIf="selectedHorseId" class="filter-indicator">
          <span>Filtered by: {{getSelectedHorseName()}}</span>
          <button (click)="clearHorseFilter()" class="clear-filter">Clear filter</button>
        </div>
      </div>
      
      <div class="results-table-container">
        <table class="results-table">
          <thead>
            <tr>
              <th (click)="sortResults('date')" class="sortable-header" [class.active]="sortColumn === 'date'">
                Date
                <span class="sort-icon" *ngIf="sortColumn === 'date'" [class.asc]="sortDirection === 'asc'" [class.desc]="sortDirection === 'desc'"></span>
              </th>
              <th (click)="sortResults('event')" class="sortable-header" [class.active]="sortColumn === 'event'">
                Event
                <span class="sort-icon" *ngIf="sortColumn === 'event'" [class.asc]="sortDirection === 'asc'" [class.desc]="sortDirection === 'desc'"></span>
              </th>
              <th (click)="sortResults('class')" class="sortable-header" [class.active]="sortColumn === 'class'">
                Class
                <span class="sort-icon" *ngIf="sortColumn === 'class'" [class.asc]="sortDirection === 'asc'" [class.desc]="sortDirection === 'desc'"></span>
              </th>
              <th (click)="sortResults('horse')" class="sortable-header" [class.active]="sortColumn === 'horse'">
                Horse
                <span class="sort-icon" *ngIf="sortColumn === 'horse'" [class.asc]="sortDirection === 'asc'" [class.desc]="sortDirection === 'desc'"></span>
              </th>
              <th (click)="sortResults('rank')" class="sortable-header" [class.active]="sortColumn === 'rank'">
                Rank
                <span class="sort-icon" *ngIf="sortColumn === 'rank'" [class.asc]="sortDirection === 'asc'" [class.desc]="sortDirection === 'desc'"></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of filteredResults">
              <td class="date-cell">
                {{result.dateFormat || formatDate(result.date) || 'N/A'}}
              </td>
              <td class="event-cell">
                <div class="event-info">
                  <img [src]="result.clubLogo" alt="Club logo" class="club-logo">
                  <span>{{result.showName}}</span>
                </div>
              </td>
              <td class="class-cell">
                <a [href]="'/competition/' + prefix + result.showId + '/' + prefix + result.competitionId" target="_blank">
                  {{result.className}}
                  <span class="height-badge">{{result.height}}</span>
                </a>
              </td>
              <td class="horse-cell">
                <a [href]="'/horse-profile/' + prefix + result.horseId" target="_blank" class="horse-link">
                  {{result.horseName || result.horse}}
                </a>
              </td>
              <td class="rank-cell">
                <span class="rank-badge"
                  [ngClass]="{
                    'rank-first': result.rank == 1,
                    'rank-second': result.rank == 2,
                    'rank-third': result.rank == 3,
                    'rank-top10': result.rank > 3 && result.rank < 11,
                    'rank-other': result.rank > 10
                  }">
                  {{result.rank}}
                </span>
              </td>
            </tr>
            <tr *ngIf="filteredResults.length === 0">
              <td colspan="5" class="no-results">
                No results found for this horse.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Add this somewhere in your template, perhaps near the rider name -->
    <div class="social-share-buttons">
      <a [href]="getWhatsAppShareUrl()" target="_blank" class="whatsapp-share-button">
        <i class="fab fa-whatsapp"></i> Share on WhatsApp
      </a>
    </div>
  </div>
</div>
