import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-concursos',
  templateUrl: './concursos.component.html',
  styleUrls: ['./concursos.component.scss']
})
export class ConcursosComponent implements OnInit {
  public concursos: any[];
  public zoneButtons: any;
  public activeButton: any;
  public zone: any;


  constructor(private db: AngularFirestore) {
    this.concursos = [];
    this.zoneButtons = '';
    this.zone = 'all';

  }

  ngOnInit() {
    this.getShows();
  }

  ngAfterViewInit() {
    let navButtons: any = document.getElementsByClassName('nav-link active');
    navButtons.forEach(element => {
      element.classList.remove('active');
    });

    document.getElementById('shows').classList.add('active');
  }

  public getShows() {
    this.db.collection('concursos', ref => ref.where('fin', '>=', new Date())).valueChanges().subscribe(
      concursos => {
        this.concursos = concursos.map((c: any) => {
            try {
            c.inicio = c.inicio.toDate();
            c.fin = c.fin.toDate();
            } catch (error) {
            console.error('Error converting date:', error);
            console.log(c);
            }

          return c
          }).sort((a, b) => {
            return a.inicio - b.inicio;
          });
      }
    );
  }

  public getFilteredShows() {
    if (this.zone == 'all') {
      let unblockedSHows = this.concursos.filter((concurso) => {
        return concurso.id != '1446' && concurso.id != '1447';

      });
      return unblockedSHows;
    }

    return this.concursos.filter((concurso) => {
      return concurso.zona == this.zone && concurso.id != '1446' && concurso.id != '1447'; 
    });

  }

  public setZone(zone) {
    this.zone = zone;
    //console.log('Setting to zone: ' + zone);
    this.getFilteredShows();
    this.zoneButtons = document.getElementsByClassName('btn-dark');
    this.zoneButtons.forEach(element => {
      element.classList.remove('btn-dark');
      element.classList.add('btn-light');
    });
    this.activeButton = document.getElementById(zone);
    this.activeButton.classList.add('btn-dark');
    this.activeButton.classList.remove('btn-light');

  }
}
