<section>
    <div class="container" style="height: 500px;">
        <div class="espnApp__hero__wrap">
            <div class="espnApp__hero__detail" >
                <div class="espnApp__hero__detail__wrap" >
                    <div class="espnApp__hero__detail__title">
                        <img src="/assets/images/appImg.webp" alt="Get the #1 Sports App"  style="border-radius: 20%;">
                        <div class="espnApp__hero__detail__title__heading" >
                            <h1>Download the Equestrian Digital app</h1>
                            <span>Take the show everywhere</span>
                        </div>
                    </div>
                    <div class="espnApp__hero__detail__download" style="display: inline-block;">
                        <a id="btn-ios-top" target="_blank" href="https://apps.apple.com/us/app/equestrian-digital/id1183799348"><img src="/assets/images/btn-ios.png" alt="App Store"></a>
                        <a id="btn-android-top" target="_blank" href="https://play.google.com/store/apps/details?id=io.vende.ecuestredigitalpublicowebview"><img src="/assets/images/btn-android.png" alt="Google Play"></a>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>