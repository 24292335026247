<div class="container">
  <div class="card mt-4">
    <div class="card-body" style="padding: 0 1rem;">
      <h1><img src="{{rider?.flag}}" alt="" class="flag"> {{rider?.riderName}}</h1>
      <div class="row">
        <div class="col-xl-6 pictureContainer">
          <div class="statsSection">
            <table class="mt-2">
              <tbody>
                <tr>
                  <th scope="row">
                    <span class="text">Country</span>
                  </th>
                  <td>{{rider?.country}}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">Club/Region</span>
                  </th>
                  <td>{{rider?.clubName}}</td>
                </tr>



                <tr>
                  <th scope="row">
                    <span class="text">Date of birth</span>
                  </th>
                  <td>{{rider?.dob}}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">Place of birth</span>
                  </th>
                  <td>{{rider?.city}}</td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>
        <div class="col-xl-6">
          <div class="statsSection">
            <table class="mt-2">
              <tbody>

                <tr>
                  <th scope="row">
                    <span class="text">Wins </span>
                  </th>
                  <td>{{wins}}</td>
                  <td style="min-width: 70px; font-weight: bold;"> {{(wins / classesEntered) | percent }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">Podiums </span>
                  </th>
                  <td>{{podiums}}</td>
                  <td style="min-width: 70px; font-weight: bold;"> {{(podiums / classesEntered) | percent }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">Classes Entered </span>
                  </th>
                  <td>{{classesEntered}}</td>
                </tr>


                <tr>
                  <th scope="row">
                    <span class="text">FEI Ranking </span>
                  </th>
                  <td>{{rider?.feiRanking}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Latest Shows -->

      <div class="row mt-2 classLink">
        <div class="col-xl-3" *ngFor="let result of resultsFb | slice:0:4; let i=index">
          <a href="/competition/{{prefix}}0/{{prefix}}{{result.ipc}}">
            <img src="{{result.showBanner}}" alt="">
            <div class="showLabel">
              {{result.order}} - {{result.showName}}
            </div>
            <div class="classHeight">
              {{result.height}} {{result.className}}
            </div>
            <div class="showDate">
              {{result.dateFormat}}
            </div>
          </a>

        </div>


      </div>

    </div>

  </div>


  <!-- Results and Horses -->
  <div class="row">

    <div class="col-xl-6 col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title mb-4">Latest Results</h4>
          <ng-container *ngFor="let result of resultsFb">
            <a href='/competition/{{prefix}}0/{{prefix}}{{result.ipc}}'
              class="d-flex resultRow mb-2">
              <img class="me-3 rounded-circle mr-2 mt-2" src="{{result.clubLogo}}" width="50" style="max-height: 50px;">
              <div class="w-100 overflow-hidden">
                <span class="badge float-right"
                  [ngClass]="{'badge-warning-lighten': result.rank == 1, 'badge-success-lighten': result.rank < 11, 'badge-info-lighten': result.rank > 10}">Rank:
                  {{result.rank}}</span>
                <h5>{{result.horseName}}</h5>
                <h5 class="mt-0 mb-1"> {{result.className}}</h5>
                <span class="font-13">{{result.showName}}</span> -
                <span class="font-13">{{result.dateFormat }}</span>
              </div>
            </a>
          </ng-container>

        </div>
        <!-- end card-body -->
      </div>
      <!-- end card-->
    </div>
    <!-- end col -->

    <div class="col-xl-6 col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title mb-4">Horses Ridden</h4>
          <ng-container *ngFor="let horse of horsesFb">
            <a href='/horse-profile/{{prefix}}{{horse.horseId}}' class="d-flex resultRow mb-2">
              <!-- <a href='' class="d-flex resultRow mb-2"> -->
              <div class="w-100 overflow-hidden">
                <h4>
                  {{horse.horseName}}
                </h4>
                
                <!-- <span *ngIf=" !horse.studbook " class="badge float-right badge-success-lighten">
                  Breed: {{horse.studbook}}</span> -->
                <h5>{{horse.horseInfo}}
                </h5>

              </div>
            </a>


          </ng-container>
        </div>
      </div>
    </div>

  </div>
</div>
