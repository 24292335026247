<section class="py-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Entry Information</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2>{{entryInfo.dorsal}} - {{entryInfo.caballo}}</h2>
        <!-- <h4>Owned by: </h4>
        <h4>Trained by: </h4> -->
      </div>
    </div>
    <h2>Registered Classes (in this show)</h2>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>Class</th>
            <th>Rider</th>
            <!-- <th>Entries</th> -->
            <th>Rank</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let class of classes">
            <td scope="row">{{class.ClassNumber }} -{{ class.ClassName}}</td>
            <td><a href="/profile/{{class.id_persona}}/{{showId}}">{{class.Rider}}</a></td>
            <!-- <td></td> -->
            <td>{{class.Rank}}</td>
            <td>{{class.Date | date}}</td>
          </tr>


        </tbody>
      </table>
    </div>

  </div>
</section>
