import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let navButtons: any = document.getElementsByClassName('nav-link active');
    navButtons.forEach(element => {
      element.classList.remove('active');
    });

    document.getElementById('info').classList.add('active');
  }

}
