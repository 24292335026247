import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Firestore, collection, query, where, getDocs, Timestamp } from '@angular/fire/firestore';

interface Circuit {
  id: string;
  name: string;
  points_system: string;
  standings: any[];
  updated_at: Date;
  club?: {
    name: string;
  };
}

@Component({
  selector: 'app-circuits',
  templateUrl: './circuits.component.html',
  styleUrls: ['./circuits.component.css']
})
export class CircuitsComponent implements OnInit {
  club_id!: string;
  season!: string;
  clubName: string = '';
  
  circuits: Circuit[] = [];
  loading: boolean = true;

  constructor(
    private firestore: Firestore,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Get route parameters
    this.route.params.subscribe(params => {
      this.club_id = params['club_id'];
      this.season = params['season'];
      this.fetchCircuits();
    });
  }

  async fetchCircuits(): Promise<void> {
    try {
      const circuitsRef = collection(this.firestore, 'circuits');
      const q = query(
        circuitsRef,
        where('club_id', '==', parseInt(this.club_id)),
        where('season', '==', parseInt(this.season))
      );

      const querySnapshot = await getDocs(q);
      this.circuits = querySnapshot.docs.map(doc => {
        const data = doc.data();
        this.clubName = data.club?.name || ''; // Set club name from first circuit
        
        // Convert Firestore Timestamp to Date
        const updated_at = data.updated_at instanceof Timestamp ? 
          data.updated_at.toDate() : 
          new Date();

        return {
          id: doc.id,
          ...data,
          updated_at // Override the Timestamp with the converted Date
        } as Circuit;
      });
      
    } catch (error) {
      console.error('Error fetching circuits:', error);
    } finally {
      this.loading = false;
    }
  }

  getUniqueHorses(circuit: Circuit): number {
    if (!circuit.standings) return 0;
    const uniqueHorses = new Set(circuit.standings.map(s => s.horseName));
    return uniqueHorses.size;
  }
}
