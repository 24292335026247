<div class="circuit-detail-container">
  <div *ngIf="loading" class="loading">
    <div class="loading-spinner"></div>
    <span>Loading circuit details...</span>
  </div>

  <div *ngIf="error" class="error">
    {{ error }}
  </div>

  <div *ngIf="circuit" class="circuit-content">
    <div class="page-header">
      <div class="header-content">
        <div class="header-title">
          <div class="club-info">
            <h1>{{ circuit.name }}</h1>
            <div class="season-tag">Season {{ circuit.season }}</div>
          </div>
          <p class="header-subtitle">{{ circuit.club?.name }}</p>
        </div>
        <div class="header-stats">
          <div class="stat-box">
            <span class="stat-value">{{ circuit.standings?.length || 0 }}</span>
            <span class="stat-label">Participants</span>
          </div>
          <div class="stat-box">
            <span class="stat-value">{{ circuit.points_system }}</span>
            <span class="stat-label">Points System</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-hint">
      <div class="rotate-icon">📱</div>
      <span>Rotate device to see all classes</span>
    </div>

    <div class="standings-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Rider / Horse</th>
            <!-- Dynamic columns for each class -->
            <th *ngFor="let classInfo of uniqueClasses">
              <a [href]="'/competition/' + classInfo.showId + '/' + classInfo.classId" 
                 target="_blank" 
                 [title]="classInfo.className">
                 <img [src]="classInfo.clubLogo" class="club-logo" alt="Show">
                {{ classInfo.classNumber }} 
               
              </a>
            </th>
            <th><b>Total Points</b></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let standing of circuit.standings">
            <td>
              <ng-container *ngIf="standing.position <= 3; else regularPosition">
                <span>{{ standing.position }}</span>
              </ng-container>
              <ng-template #regularPosition>
                {{ standing.position }}
              </ng-template>
            </td>
            <td class="rider-horse-cell">
              <img [src]="standing.binomio?.rider?.badge || 'assets/default-club.png'" 
                   [alt]="standing.binomio?.rider?.club?.name"
                   class="club-badge"
                   [title]="standing.binomio?.rider?.club?.name">
              <small>{{standing.clubSiglas}}</small>
              <div class="names-container">
                <div class="rider-name">
                  <a [routerLink]="['/rider-profile',standing.riderId]" target="_blank">
                    {{ standing.riderName }}
                  </a>
                </div>
                <div class="horse-name">
                  <a [routerLink]="['/horse-profile',standing.horseId]" target="_blank">
                    {{ standing.horseName }}
                  </a>
                </div>
              </div>
            </td>
            <!-- Dynamic cells for each class -->
            <td *ngFor="let classInfo of uniqueClasses">
              {{ getPointsForClass(standing, classInfo.classId) }}
            </td>
            <td>{{ standing.totalPoints }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="!circuit && !loading">
    Circuit did not load
    <p>Error: {{ error }}</p>
  </div>
</div> 