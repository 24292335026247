import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArchiveComponent } from './archive/archive.component';
import { ClipViewerComponent } from './clip-viewer/clip-viewer.component';
import { CompetitionComponent } from './competition/competition.component';
import { ConcursosComponent } from './concursos/concursos.component';
import { EntryComponent } from './entry/entry.component';
import { HorseProfileComponent } from './horse-profile/horse-profile.component';
import { InfoComponent } from './info/info.component';
import { MasterlistComponent } from './masterlist/masterlist.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RiderProfileComponent } from './rider-profile/rider-profile.component';
import { ShowComponent } from './show/show.component';
import { DivisionChampionsComponent } from './division-champions/division-champions.component';
import { AppLandingComponent } from './app-landing/app-landing.component';
import { ChampionshipsComponent } from './championships/championships.component';
import { ChampionshipComponent } from './championship/championship.component';
import { ProfileComponent } from './profile/profile.component';
import { CircuitsComponent } from './circuits/circuits.component';
import { CircuitDetailComponent } from './circuit-detail/circuit-detail.component';

const routes: Routes = [
  {
    path: 'concursos',
    component: ConcursosComponent,
    pathMatch: 'full'
  },
  {
    path: 'profile/:personID/:showID',
    component: ProfileComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: ConcursosComponent,
    pathMatch: 'full'
  },
  {
    path: '*',
    component: ConcursosComponent,
    pathMatch: 'full'
  },
  {
    path: 'app-landing',
    component: AppLandingComponent,
    pathMatch: 'full'
  },
  {
    path: 'farmtek',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'archive',
    component: ArchiveComponent,
    pathMatch: 'full'
  },
  {
    path: 'show/:showId',
    component: ShowComponent,
    pathMatch: 'full'
  },
  {
    path: 'competition/:showId/:competitionId',
    component: CompetitionComponent,
    pathMatch: 'full'
  },
  {
    path: 'privacy-policy',
    component: PrivacyComponent,
    pathMatch: 'full',
  },
  {
    path: 'info',
    component: InfoComponent,
    pathMatch: 'full',
  },
  {
    path: 'clip-viewer/:clipId',
    component: ClipViewerComponent
  },
  {
    path: 'rider-profile/:riderId',
    component: RiderProfileComponent,
    data: {
      title: 'Rider Profile',
      description: 'Detailed profile with rider info and results history.',
      ogTitle: 'ED - Rider Profile',
      ogDescription: 'ED - Detailed profile with rider info and results history. ',
      ogImage: '/assets/images/logo-ed.png'
    }
  },
  {
    path: 'horse-profile/:horseId',
    component: HorseProfileComponent,
    data: {
      title: 'Horse Profile',
      description: 'Detailed profile with horse info and results history.',
      ogTitle: 'ED - Horse Profile',
      ogDescription: 'ED - Detailed profile with horse info and results history. ',
      ogImage: '/assets/images/logo-ed.png'
    }
  },
  {
    path: 'masterlist/:showId',
    component: MasterlistComponent,
    pathMatch: 'full'
  },
  {
    path: 'entry/:showId/:entryId',
    component: EntryComponent,
    pathMatch: 'full'
  },
  {
    path: 'divisionChampions/:showId',
    component: DivisionChampionsComponent,
    pathMatch: 'full'
  },
  {
    path: 'championships/:showId',
    component: ChampionshipsComponent,
    pathMatch: 'full'
  },
  {
    path: 'championship/:spreadsheetId',
    component: ChampionshipComponent,
    pathMatch: 'full'
  },
  {
    path: 'circuits/:club_id/:season',
    component: CircuitsComponent,
  
  },
  {
    path: 'circuits/:club_id/:season/:circuit_id',
    component: CircuitDetailComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
