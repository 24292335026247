import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonService } from '../services/person.service';
declare const $: JQueryStatic;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  public trainerInfo: any;
  public trainers: any[];
  public showInfo: any;
  public showId: any;
  public personId: any;
  public personInfo: any;
  public entries: any[];
  constructor(
    private _route: ActivatedRoute,
    private personService: PersonService
  ) {
    this.personId = (this._route.snapshot.params?.personID??'').replace('us_','')??'';
    this.showId = (this._route.snapshot.params?.showID??'').replace('us_','')??'';
  }

  ngOnInit(): void {
    this.getPersonData(this.personId, this.showId);
  }

  public getPersonData(personId, showId){
    if(!personId || !showId){
      $.NotificationApp.send('Error', 'Invalid person or show ID', 'bottom-right', '#bf441d', 'error');
      return;
    }
    this.personService.getPerson(personId, showId).subscribe(response => {
      this.personInfo = response.data.personInfo;
      this.entries = response.data.results;
    });
  }
}

export interface NotificationApp {
  /**
   * Send Notification
   *
   * @param heading heading text
   * @param body body text
   * @param position position e.g top-right, top-left, bottom-left, etc
   * @param loaderBgColor loader background color
   * @param icon icon which needs to be displayed
   * @param hideAfter automatically hide after seconds
   * @param stack stack
   * @param showHideTransition show hide transition
   */
  send: (
    heading: string,
    body: string,
    position:' bottom-left' | 'bottom-right' | 'bottom-center' | 'top-right' | 'top-left' | 'top-center' | 'mid-center',
    loaderBgColor: string,
    icon: 'error' | 'success' | 'warning' | 'info',
    hideAfter?: number,
    stack?: number,
    showHideTransition?: boolean
  ) => void;
}

export interface JQueryStatic{
  NotificationApp: NotificationApp;
}
