import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConcursosComponent } from './concursos/concursos.component';
import { NavbarComponent } from './navbar/navbar.component';
//Angular Fire
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore/';
//Angular Fire actualizado
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { environment } from '../environments/environment';
import { FooterComponent } from './footer/footer.component';
import { ShowComponent } from './show/show.component';
import { CompetitionComponent } from './competition/competition.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ArchiveComponent } from './archive/archive.component';
import { SafePipe } from './safe.pipe';
import { InfoComponent } from './info/info.component';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { ClipViewerComponent } from './clip-viewer/clip-viewer.component';

import { CommonModule, Location } from "@angular/common";
import { RiderProfileComponent } from './rider-profile/rider-profile.component';
import { HorseProfileComponent } from './horse-profile/horse-profile.component';
import { MasterlistComponent } from './masterlist/masterlist.component';
import { EntryComponent } from './entry/entry.component';
import { DivisionChampionsComponent } from './division-champions/division-champions.component';
import { AppLandingComponent } from './app-landing/app-landing.component';
import { ChampionshipsComponent } from './championships/championships.component';
import { ChampionshipComponent } from './championship/championship.component';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
  declarations: [
    AppComponent,
    ConcursosComponent,
    NavbarComponent,
    FooterComponent,
    ShowComponent,
    CompetitionComponent,
    PrivacyComponent,
    ArchiveComponent,
    SafePipe,
    InfoComponent,
    ClipViewerComponent,
    RiderProfileComponent,
    HorseProfileComponent,
    MasterlistComponent,
    EntryComponent,
    DivisionChampionsComponent,
    AppLandingComponent,
    ChampionshipsComponent,
    ChampionshipComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    CommonModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
  ],
  providers: [
    ScreenTrackingService,
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
Location.stripTrailingSlash = function stripTrailingSlashNew(url: string) {
  return url;
};
