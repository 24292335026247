import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-division-champions',
  templateUrl: './division-champions.component.html',
  styleUrls: ['./division-champions.component.scss']
})
export class DivisionChampionsComponent implements OnInit {

  public showId: any;
  public divisiones: any;

  constructor(private route: ActivatedRoute, private http: HttpClient) { 
    this.showId = this.route.snapshot.paramMap.get('showId');
  }

  ngOnInit(): void {
    this.getDivisionChampions();
  }



      
  getDivisionChampions() {
    // Get division champions
  
    let headers = {
      'X-Api-Key': '7cc263a1-a490-4337-8302-151490495e56'
    };

    let params = {
      'idConcurso': this.showId.replace('us_', '')
    };

    this.http.post('https://api.equestrian.digital/pruebas/divisiones/', params, { headers: headers }).subscribe((data: any) => {
      this.divisiones = data.divisiones;
      console.log(data);
  
    }
  );}
  

}
